<template>
  <v-card>
    <v-row class="floatrow">
      <v-dialog
          v-model="dialog"
          scrollable
          max-width="600px"
      >
        <!--        <template v-slot:activator="{ on, attrs }">-->
        <!--          <v-btn-->
        <!--              color="primary"-->
        <!--              dark-->
        <!--              v-bind="attrs"-->
        <!--              v-on="on"-->
        <!--              class="new_button"-->
        <!--          >-->
        <!--            <v-icon>mdi-plus</v-icon>-->
        <!--          </v-btn>-->
        <!--        </template>-->
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" sm=6 md=6>
                  <v-text-field
                      label="Nom d'utilisateur"
                      required
                      disabled
                      v-model=user.username
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm=6 md=6>
                  <v-text-field
                      label="Mot de passe"
                      required
                      v-model=user.password
                      type="number"
                      :rules="[v => /^\d+$/.test(v) || 'Seulement des chiffres sont autorisés']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red"
                class="close_modal_button"
                text
                @click="close"
            >
              Fermer
            </v-btn>
            <v-btn
                color="blue"
                text
                class="confirm_modal_button"
                v-on:click=save
                :loading="loading"
                :disabled="loading"
                @click="loader = 'loading'"
            >
              Valider
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-card-title>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          class="tablesearch"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
    >

      <template v-slot:item.status="{ item }">

        <v-chip
            :class="getStatusColor(item.status)"
        >
          {{ getStatusLabel(item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2 edit_button"
            @click="edit(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <FlashMessage :position="'right bottom'"></FlashMessage>
  </v-card>
</template>

<script>
// import store from '@/store/index.js';

export default {
  props: {
    users: [],
    devices: [],
    stores: [],
    business: [],
    renewals: []
  },
  methods: {
    edit: function (item) {
      this.index = this.users.indexOf(item)
      this.user = Object.assign({}, item)
      this.user.user_id = this.user.id
      this.form_title = "Editer utilisateur : " + this.user.username
      this.dialog = true
    },
    close: function () {
      this.dialog = false
      this.reset()
      this.loading = false
    },
    getStatusColor: function (status) {
      var color = "class_red"
      if (status) {
        color = "class_green"
      }
      return color
    },
    getDeviceLabel: function (item) {
      var label = ""
      for (var i = this.devices.length - 1; i >= 0; i--) {
        if (this.devices[i].id == item) {
          label = this.devices[i].name
        }
      }
      return label
    },
    getStatusLabel: function (status) {
      var label = "Inactif"
      if (status) {
        label = "Actif"
      }
      return label
    },
    getAmount: function (amount) {
      return amount + " HTG"
    },
    getRenewalNumber: function (id) {
      var renewal_number = 10000 + parseInt(id)
      return renewal_number
    },
    save: function () {

      var self = this;
      this.loading = true
      self.user.password = Number(self.user.password)
      this.$axios.post("/dashboard/businesses/password/" + self.business.id, self.user)
          .then(response => {
            // console.log(response);
            this.flashMessage.success({
              title: 'Succès',
              message: response.data.message
            });

            self.dialog = false
            self.loading = false
            self.reset()

          }).catch(function (error) {
              console.log("error", error)
              self.flashMessage.error({
                  title: 'Erreur !',
                  message: "Une erreur inattendue s'est produite. Vérifiez les données envoyées"
              });
              self.loading = false
          });
    },
    updateDevice: function (data) {
      var self = this;
      url = '/devices/' + data.device_id
      var today = new Date()
      var paid_until = new Date(data.paid_until)
      var status = false
      if (today < paid_until) {
        status = true
      }
      var url = '/devices/' + data.device_id

      this.$axios.post(url, {next_renewal_date: data.paid_until, status: status})
          .then(response => {
            for (var i = self.devices.length - 1; i >= 0; i--) {
              if (self.devices[i].id == response.data.id) {
                self.devices[i] = response.data
              }
            }
          })
    },
    reset: function () {
      this.renewal = {
        device_id: '',
        renewal_date: (new Date(Date.now())).toISOString().substr(0, 10),
        status: 1,
        amount: 0,
        memo: '',
        paid_until: (new Date(Date.now())).toISOString().substr(0, 10),
      }
      this.index = -1
      this.form_title = 'Nouvel utilisateur'
      this.user = {
        id: '',
        user_id: '',
        username: '',
        phone: '',
        status: 1,
        password: '',
      }
    }
  },
  data() {
    return {
      search: '',
      menu: false,
      modal: false,
      menu2: false,
      loading: false,
      status: [{name: "Actif", id: true}, {name: 'Annulé', id: false}],
      dialog: '',
      index: -1,
      form_title: 'Nouvel usilisateur',
      renewal: {
        device_id: '',
        renewal_date: (new Date(Date.now())).toISOString().substr(0, 10),
        status: 1,
        amount: 0,
        memo: '',
        paid_until: (new Date(Date.now())).toISOString().substr(0, 10),
      },
      user: {
        id: '',
        user_id: '',
        username: '',
        phone: '',
        status: 1,
        password: '',
      },
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {text: 'Non d\'utilisateur', value: 'username'},
        {text: 'Nom complet', value: 'full_name'},
        {text: 'Téléphone', value: 'phone'},
        {text: 'STATUT', value: 'status'},
        {text: 'ACTIONS', value: 'actions'}
      ],
    }
  },

  mounted() {
    console.log("Mounted Hook Executed");
    console.log("USERS", this.users);
  },

  load: function () {
    console.log("Mounted load Executed");

  }
}
</script>
<style type="text/css">
.tablesearch .v-input__slot:before {
  display: none;
}

.floatrow {
  text-align: right;
  float: right;
  margin-right: 21px;
  margin-left: 5px;
}

.new_button {
  width: 50px;
  margin-top: 46px !important;
  float: right;
}

.tablesearch .v-input__control {
  border: 1px solid #ddd;
  padding: 5px 10px;
}

.edit_button {
  font-size: 16px;
  padding: 5px;
  background: #003f74 !important;
  color: white !important;
  border-radius: 4px;
}

.view_button {
  font-size: 16px;
  padding: 5px;
  background: #fb8c00 !important;
  color: white !important;
  border-radius: 4px;
}

td.text-start:last-child, th.text-start:last-child {
  text-align: right !important;
}

.v-dialog .v-card__title {
  background: #f3f3f3;
  border-bottom: 1px solid #ddd;
}

.class_green {
  background: #4caf50 !important;
  color: white !important;
}

.class_red {
  background: #ff5252 !important;
  color: white !important;
}

.v-btn.close_modal_button {
  background-color: #1976d2 !important;
  color: white !important;
}

.v-btn.confirm_modal_button {
  background-color: #4caf50 !important;
  color: white !important;
}

.v-dialog .v-card__actions {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
  background: #f3f3f3;
  border-top: 1px solid #ddd;
}

.tablesearch .v-input__slot:before {
  display: none;
}

.v-card__actions .v-progress-circular {
  margin-top: 1px !important;
}

.v-card__actions .v-progress-circular circle {
  color: white !important;
}
</style>